body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
}

@font-face {
  font-family: 'Smoothy';
  src: local('Smoothy'),
    url(../src/fonts/Smoothy/Smoothy-Regular.ttf) format('truetype');
}

.common-color{
  color: white !important;
  background: linear-gradient(to right, #fb794a, #edb038);
}

.badgeColor{
  color:#edb038;
}
.pdf-button{
  color: white !important;
  background-color: #383838 !important;
}

.baseline{
  display: flex;
  align-items: baseline !important;
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar{
  display: flex;
  align-items: baseline !important;
}


.brown{
  color: white !important;
  background-color: #383838 !important;
}


.slate{
  background-color:rgba(241, 245, 249, 1);
  display: flex;
  flex-direction: column;
  height: 100vh; 
 
  color: rgba(241, 245, 249, 1);;
 

}


@media print {
  html, body {
     display: none;  /* hide whole page */
  }
}