.CoursePage {
  margin-top: 56px;
}

.CoursePage-title-container {
  background-color: #383838;
  color: #ffffff;
  text-align: center;
  padding: 2rem 0 10rem 0;
}
.CoursePage-title-container h1 {
  font-size: 2.3rem;
  font-weight: 400;
  margin-bottom: 0.7rem;
}
.CoursePage-title-container h2 {
  font-weight: 400;
  margin-bottom: 0.5rem;
}
.CoursePage-title-container p {
  font-size: 0.8rem;
  font-weight: 300;
  width: 55%;
  margin: 0 auto;
}

.chapter-card-container {
  width: 70%;
  margin: -7rem auto 7rem auto;
  border: 1px solid #d1d9d9;
  border-radius: 8px;
  overflow: hidden;
}
