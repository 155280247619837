.LoginModal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-animation-name: loginFadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: loginFadeIn;
  animation-duration: 0.4s;
  color: black;
}

.LoginModal-content {
  position: absolute;
  right: 0;
  background-color: #fefefe;
  width: 70%;
  height: 100%;
  -webkit-animation-name: loginSlideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: loginSlideIn;
  animation-duration: 0.4s;
  text-align: center;
}

.LoginModal-close-icon {
  position: absolute;
  left: -2rem;
  top: 3%;
  width: 20px;
  cursor: pointer;
}

.LoginModal-container {
  width: 75%;
  margin: 0 auto;
}
.LoginModal-container-teacher{
  width: 75%;
  margin: 0 auto;
}

.login-title {
  font-weight: 410;
  margin: 4rem auto 1.3rem auto;
  text-align: left;
  font-size: 1.6rem;
}

.login-title-teacher {
  font-weight: 410;
  margin: 4rem 1.5rem 1.3rem auto;
  text-align: center;
  font-size: 1.6rem;
}


.LoginModal-content p {
  font-size: 0.8rem;
  font-weight: 400;
  width: 86%;
}

.LoginModal-input {
  width: 86%;
  margin: 0.4rem 0rem;
  padding: 0.8rem 1rem;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  border: 1px solid #d1d9d9;
  border-radius: 2rem;
}
.LoginModal-input:focus {
  outline: none;
}

.LoginModal-inputDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.login-error-msg {
  text-align: center;
  margin: 1rem;
  color: red;
  font-size: 0.8rem;
}

.forgot-password-msg {
  width: 86%;
  text-align: right;
  margin-top: 2px;
  padding-right: 8px;
}

.forgot-password-msg:hover {
  cursor: pointer;
  color: #4963e1;
}

.LoginModal-inputDiv input.error {
  border: 1px solid red;
}

.menuDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  
}

.LoginModal-menuDiv.error {
  border: 1px solid red !important;
}

.LoginModal-menuDiv {
  text-align: left;
  position: relative;

}

.LoginModal-menuDiv .placeholder {
  color: #808080;
}

.menu-container {
  position: absolute;
  top: 5px;
  left: 0px;
  width: 99%;
  background-color: #fff;
  padding: 0.6rem 0;
  border-radius: 0 0 8px 8px;
  border: 1px solid #d1d9d9;
  border-top: none;
}

.menu-container div {
  padding: 0.6rem 0 0.6rem 2rem;
  font-size: 0.8rem;
  margin: 0;
  border-radius: 2rem 0 0 2rem;
}

.menu-item {
  position: relative;
  color: #808080;
}

.menu-item:hover {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  color: #ffffff !important;
  cursor: pointer;
}

.errorMessage {
  color: red;
  font-size: 0.75em;
  display: relative;
  /* text-align: left; */
  margin-bottom: 5px;
  margin-left: 18px;
  margin-right: auto;
}

.login-button {
  width: 86%;
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.7rem 1.8rem;
  border-radius: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  margin: 1.3rem 0;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
}

.create-account-button {
  width: 86%;
  border: none;
  cursor: pointer;
  color: #f49241;
  background: #ffffff;
  padding: 0.7rem 1.8rem;
  border: 1px solid #f0a73b;
  border-radius: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  margin: 0.7rem 0;
  display: flex;
  justify-content: center;
}

/* .LoginModal-divider {
  border-top: 1px solid #d1d9d9;
  width: 40%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
} */
.LoginModal-divider {
  border-top: 1px solid #d1d9d9;
  width: 40%;
  margin: 2rem auto;
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically if inside a column flex container */
}


.back-icon-container {
  text-align: left;
  margin-top: 1.5rem;
}

.back-icon {
  width: 1.8rem;
  cursor: pointer;
  padding-top: 0rem;
}

.create-account-title {
  font-weight: 400;
  margin: 1.2rem auto 1.3rem auto;
  text-align: left;
  font-size: 1.6rem;
}

.success-icon {
  width: 25%;
  margin-top: 6rem;
}

.success-title {
  font-family: Smoothy, Arial, serif;
  font-size: 2.2rem;
  font-weight: 400;
  margin-bottom: 0;
  line-height: 3.2rem;
}

.success-text {
  color: grey;
}

/* Add Animation */
@-webkit-keyframes loginSlideIn {
  from {
    right: -100px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.new-login-div{
  width: 86%;
  display: flex;
  justify-content: space-between;
}
.login-new {
  width: 20rem;
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.7rem 0.8rem;
  border-radius: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  margin: 1.3rem 0;
  margin-top: 0.5rem;
  /* display: flex;
  justify-content: center; */
}





@keyframes loginSlideIn {
  from {
    right: -100px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@-webkit-keyframes loginFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes loginFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
