.HomePage {
  margin-top: 56px;
}

.welcome-section {
  background-color: #383838;
  position: relative;
  margin-top: 56px;
}

.hero-img {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  overflow: auto;
  object-fit: contain;
  width: 100%;
}

.welcome-textbox {
  position: absolute;
  color: #ffffff !important;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.welcome-text1 {
  margin: 4rem 0 0;
  font-size: 2rem;
  z-index: 10;
  color: #ffffff !important;
}

.welcome-text2 {
  font-family: Smoothy, Arial, serif;
  font-size: 6.5vw;
  font-weight: 400;
  margin: 0;
  z-index: 10;
  color: #ffffff !important;
}

.curved-div {
  height: 150px;
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: -1px;
}

.university-section-container {
  width: 60%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
  position: relative;
  height: 225px;
  margin-bottom: 200px;
}

.university-section-content-box-left {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-right: 20px;
}

.university-section-content-box-left h1 {
  font-size: 3.5rem;
  font-weight: 800;
  margin: 2rem 0 0.3rem;
}

.ss {
  display: flex;
  flex-direction: column;
}

.university-section-content-box-left p {
  font-size: 0.9rem;
  margin-top: 0.7rem;
}

.fav-icon-container {
  width: 45%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.fav-icon {
  width: 15%;
}

.university-section-content-box-right {
  width: 100%;
  height: 100%;
  margin-left: 50px;
  margin-bottom: 20px;
}

.university-section-content-box-right h1 {
  font-weight: 400;
}

.university-section-content-box-right p {
  font-size: 0.8rem;
  color: grey;
}

.university-icon-container {
  width: 25%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.university-logo {
  width: 45%;
}
@media (max-width: 767px) {
  .university-section-container {
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    height: auto;
    margin-bottom: 100px;
  }

  .university-section-content-box-left {
    width: 100%;
    text-align: center;
    padding-right: 0;
    margin-bottom: 1rem;
  }

  .university-section-content-box-left h1 {
    font-size: 2.5rem;
  }

  .university-section-content-box-left p {
    font-size: 0.8rem;
  }

  .fav-icon-container {
    width: 80%;
    justify-content: center;
  }

  .fav-icon {
    width: 10%;
  }

  .university-section-content-box-right {
    width: 100%;
    margin-left: 0;
  }

  .university-section-content-box-right h1 {
    font-size: 1.5rem;
  }

  .university-section-content-box-right p {
    font-size: 0.7rem;
  }

  .university-icon-container {
    width: 50%;
  }

  .university-logo {
    width: 40%;
  }
}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .university-section-container {
    height: auto;
    margin-bottom: 150px;
  }

  .university-section-content-box-left {
    width: 40%;
  }

  .university-section-content-box-left h1 {
    font-size: 3rem;
  }

  .university-section-content-box-left p {
    font-size: 0.85rem;
  }

  .fav-icon-container {
    width: 60%;
  }

  .fav-icon {
    width: 12%;
  }

  .university-section-content-box-right {
    margin-left: 30px;
  }

  .university-section-content-box-right h1 {
    font-size: 1.75rem;
  }

  .university-section-content-box-right p {
    font-size: 0.75rem;
  }

  .university-icon-container {
    width: 30%;
  }

  .university-logo {
    width: 50%;
  }
}
.features-section {
  width: 100%;
  margin: 0 auto;
  padding: 7rem 2rem;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
}

.features-section-title {
  width: 100%;
  text-align: center;
  margin-bottom: 3rem;
}

.features-section-title h1 {
  font-weight: 400;
  line-height: 3rem;
}

.features-container {
  display: flex;
  flex-direction: column;
}

.features-container-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  margin-bottom: 2rem;
}

.feature-item {
  width: calc(33.333% - 1.33rem);
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

.feature-img {
  width: 100px;
  margin: 0 auto 1rem;
}

.feature-text {
  font-size: 0.9rem;
  line-height: 1.5rem;
}



.event-curved-div {
  width: 100%;
  height: 150px;
  overflow: hidden;
  margin-bottom: -1px;
}

.events-section-text-div {
  background-color: #2f2f2f;
  color: #ffffff;
  text-align: center;
  padding-top: 2rem;
}

.events-section-text-div h1 {
  color: #ffffff !important;
  font-family: Smoothy, Arial, serif;
  font-size: 4rem;
  font-weight: 400;
  margin: 0;
  line-height: 5rem;
}

.events-section-text-div p {
  font-size: 0.8rem;
  line-height: 1.4rem;
  margin: 0.5rem 0 0;
}

.events-section-title-container {
  width: 50%;
  margin: 0 auto;
}

.events-section-subtitle-container {
  width: 35%;
  margin: 0 auto;
}

.events-section-container {
  text-align: center;
  background: linear-gradient(to bottom, #2f2f2f, #434343);
  position: relative;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.events-section-container button {

  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.7rem 1.6rem;
  border-radius: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .events-section-container button {
    padding: 0.6rem 1.4rem;
     font-size: 0.9rem;
  }
}
@media (max-width: 480px) {
  .events-section-container button {

    font-size: 0.8rem;
    padding: 0.5rem 1.2rem;
    top: 1rem; /* Further adjust the top positioning */
  }
}
.events-img {
  width: 60%;
  margin: 0 auto;
}

.experience-section-container {
  width: 60%;
  margin: 5rem auto;
}

.experience-section-container h1 {
  font-weight: 400;
}

.experience-section-content-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.experience-section-content-div-left {
  width: 40%;
}

.experience-section-content-div-left p {
  font-size: 0.8rem;
}

.experience-section-content-div-right {
  width: 50%;
}

.teacher-img {
  margin-top: -2rem;
}
.workshops-section {
  margin: 0 auto;
  padding: 2rem 0;
  background-color: #f4f4f4;
}

.workshops-section h1 {
  width: 60%;
  margin: 0 auto;
  font-weight: 400;
  text-align: center;
}

.workshops-section-card-container {
  width: 80%;
  margin: 2rem auto 7rem;
}

.workshops-section-card-container-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem; 
  justify-content: flex-start; 
}
.workshops-section-card-container-row:last-child {
  justify-content: center; 
}

.workshops-section-card {
  width: calc(25% - 1.5rem); 
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 2rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
}

.workshop-img  {
  width: 100%;
  /* height: 200px;  */
  transition: transform 0.3s ease;
  /* object-fit: cover;  */
  border-radius: 10px; 
}

.workshops-section-card p {
  font-size: 16px;
  line-height: 1.4rem;
  color: #333;
}
@media (max-width: 767px) {
  
  .workshops-section{
    flex-direction: column;
    align-items: center;
  }
  .workshops-section-card{
    flex: 1 1 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  

}

/* Tablet */
@media (min-width: 768px) and (max-width: 1023px) {
  .workshops-section h1 {
    width: 80%; /* Adjust width for tablet */
    font-size: 1.75rem; /* Adjust font size for tablet */
  }

  .workshops-section-card-container {
    width: 90%; /* Adjusted width for tablet */
  }

  .workshops-section-card {
    width: calc(50% - 1rem); /* Two cards per row on tablet */
  }
}


.testimonial-section {
  position: relative;
  background: linear-gradient(to bottom, #2f2f2f, #313131);
}

.empty-div {
  height: 150px;
  background: #ffffff;
}

.testimonial-curved-div {
  position: absolute;
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 150px;
}

.testimonial-section-container {
  width: 65%;
  margin: 0 auto;
  position: relative;
}

.testimonial-section-title-div {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.testimonial-section-title-div h1 {
  width: 50%;
  color: #ffffff;
  font-family: Smoothy, Arial, serif;
  font-size: 4.5rem;
  font-weight: 400;
  line-height: 5.5rem;
  margin-top: 2rem;
}

.slideshow {
  overflow: hidden;
  width: 100%;
  padding-top: 27%;
}

.slideshowSlider {
  position: relative;
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  width: 100%;
}

.slide h1 {
  white-space: normal;
}

.slide-full-div {
  padding-top: 223px;
}

.slide-full-div-text {
  position: absolute;
  top: 0;
  width: 100%;
}

.slideshowDotsContainer {
  bottom: 0;
  right: -5rem;
  align-items: center;
  margin-left: 40%;
  padding-bottom: 10%;
}

.slideshowDot {
  cursor: pointer;
  height: 0.5rem;
  width: 0.5rem;
  margin: 0 3px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.checked {
  background-color: #3eac72 !important;
}

.testimonial-section-content-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #ffffff;
}

.testimonial-section-content-box-left {
  width: 50%;
}

.testimonial-section-content-box-right {
  width: 50%;
}

.student-img {
  margin: 0 auto;
  width: 300px;
  height: 300px;
  border-radius: 100% 0% 100% 100%;
}

.star-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.star-container span {
  font-size: 1.5rem;
}

.team-section {
  width: 80%;
  margin: 0 auto;
}

.team-section h1 {
  font-weight: 400;
  text-align: center;
}

.team-section-content-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.team-section-content-box-item {
  width: 20%;
  text-align: center;
}

.team-section-content-box-item img {
  width: 80%;
}

.team-section-content-box-item p {
  font-size: 0.8rem;
}

.team-member-img {
  margin-top: -3rem;
}

.career-section-container {
  width: 80%;
  margin: 0 auto;
}

.career-section-container h1 {
  font-weight: 400;
}

.career-section-content-box {
  width: 60%;
  margin: 0 auto;
}

.career-section-content-box h2 {
  text-align: center;
  margin-bottom: 2rem;
}

.career-section-content-box-left {
  width: 50%;
}

.career-section-content-box-right {
  width: 50%;
}

.career-section-content-box-left p,
.career-section-content-box-right p {
  font-size: 0.8rem;
}

.career-img {
  width: 70%;
  margin: 2rem auto;
}

.contact-section-container {
  width: 80%;
  margin: 0 auto;
}

.contact-section-container h1 {
  font-weight: 400;
  text-align: center;
}

.contact-section-content-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.contact-section-content-box-left,
.contact-section-content-box-right {
  width: 45%;
}

.contact-section-content-box-left p,
.contact-section-content-box-right p {
  font-size: 0.8rem;
}

.contact-img {
  width: 100%;
  margin: 2rem auto;
}
.conclusion-section {
  background-color: #f8f8f8;
  padding: 2rem;
  text-align: center;
}

.conclusion-section h1 {
  font-family: Smoothy, Arial, serif;
  font-size: 2.5rem;
  font-weight: 400;
  margin: 0;
}

.conclusion-section p {
  font-size: 1rem;
  color: #333;
  margin-top: 1rem;
}


.conclusion-section button {

  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.7rem 1.6rem;
  border-radius: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .conclusion-section button {
    padding: 0.6rem 1.4rem;
     font-size: 0.9rem;
  }
}
@media (max-width: 480px) {
  .conclusion-section button {

    font-size: 0.8rem;
    padding: 0.5rem 1.2rem;
    top: 1rem; /* Further adjust the top positioning */
  }
}