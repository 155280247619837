/*-------------1 column*/
.ft-1 h3 {
  font-weight: 700;
  font-family: cursive;
  letter-spacing: 2px;
  padding: 0.5rem 0;
  font-size: 2rem;
}

.ft-1 span {
  color: hsla(40, 97%, 46%, 0.953);
}

.ft-1 p {
  padding: 0rem 5rem 0.8rem 0;
  font-weight: 500;
}

/*.footer-icons i {
  padding: 0.4rem 0.5rem;
  background: hwb(0 94% 4%);
  color: hsla(40, 97%, 46%, 0.953);;
  margin: 0 0.5rem;
  border-radius: 50%;
}

.footer-icons i:hover {
  background: hsla(40, 97%, 46%, 0.953);
  color: #f3f2f2;
  transition: 0.6s;
  cursor: pointer;
}*/

/*-----------2column for contact*/
.ft-3 p {
  font-weight: 500;
  padding: 0.1rem 0;
  font-size: 1.06rem;
}

.ft-3 i {
  padding-right: 0.5rem;
}


/*------For Footer*/

@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,700);
/*body {
 font-family:Rubik,sans-serif;
 position:relative;
 font-weight:400;
 font-size:15px
}
 /*ul {
 padding:0;
 margin:0
}*/
li {
 list-style:none
}
a:focus,a:hover {
 text-decoration:none;
 -webkit-transition:.3s ease;
 -o-transition:.3s ease;
 transition:.3s ease
}
a:focus {
 outline:0
}
/*img {
 max-width:100%
}*/
/*p {
 font-size:16px;
 line-height:30px;
 color:#898b96;
 font-weight:300
}*/
.footer-widget-title .h4 {
 font-family:Rubik,sans-serif;
 margin:0;
 font-weight:400;
 padding:0;
 color:#2ab600
}
/*/a {
 color:#5867dd
}*/
.no-padding {
 padding:0!important
}
.go_top {
 line-height:40px;
 cursor:pointer;
 width:40px;
 background:hsl(40, 91%, 41%);
 color:#fff;
 position:fixed;
 -webkit-box-shadow:0 4px 4px rgba(0,0,0,.1);
 box-shadow:0 4px 4px rgba(0,0,0,.1);
 -webkit-border-radius:50%;
 border-radius:50%;
 right:-webkit-calc((100% - 1140px)/ 2);
 right:calc((100% - 1140px)/ 2);
 z-index:111;
 bottom:80px;
 text-align:center
}
/*.go_top span {
 display:inline-block
}*/
.footer-big {
 padding:105px 0 65px 0;
}
.footer-big .footer-widget {
 margin-bottom:40px
}
.footer--light {
 background:#030511
}
.footer-big .footer-menu ul li a,.footer-big p,.footer-big ul li {
 color:z#898b96
}
.footer-menu {
 padding-left:48px
}
.footer-menu ul li a {
 font-size:15px;
 line-height:32px;
 -webkit-transition:.3s;
 -o-transition:.3s;
 transition:.3s
}

.footer-menu--1 {
 width:100%
}
.footer-widget-title {
 line-height:42px;
 margin-bottom:10px;
 font-size:18px

}
.mini-footer {
 background:var(--bs-body-color);;
 text-align:center;
 padding: 12px;

}
.mini-footer p {
 margin:0;
 line-height:26px;
 font-size:15px;
 color:rgb(255, 255, 255)
 
}

.col-md-3 {
  color: hsl(0, 20%, 91%);
}



/*-------------------icons----*/

.social-media-links {
  display: flex;
  justify-content: flex-start; /* Center align the icons horizontally */
  gap: 1rem; /* Adjust spacing between icons as needed */
  margin-top: 1rem; /* Space between the paragraph and the icons */
}

.social-media-links a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem; /* Adjust the width of the circle */
  height: 3rem; /* Adjust the height of the circle */
  border-radius: 50%; /* Makes the element circular */
  background-color: #f3f3e4; /* Background color of the circle */
  border: 2px solid black; /* Black border around the circle */
  text-decoration: none;
}

.social-media-links a svg {
  color: black; /* Color of the icon */
  font-size: 1.5rem; /* Adjust the size of the icons */
}

.social-media-links a:hover {
  background-color: hwb(47 9% 9% / 0.912); /* Change to your desired hover background color */
}

/* General styles for desktop and larger screens */
.footer-area {
  padding: 20px 0;
}

/* For mobile devices (less than 768px) */
@media (max-width: 767px) {
  .footer-big {
    padding: 50px 0 30px 0;
  }
  .col-md-3 {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
  }
  .footer-menu {
    padding-left: 0;
  }
  .footer-menu ul {
    padding-left: 0;
    list-style: none;
    text-align: center;
  }
  .footer-menu ul li {
    margin-bottom: 10px;
  }
  .footer-menu ul li a {
    font-size: 14px;
  }
  .social-media-links {
    justify-content: center;
  }
  .mini-footer p {
    font-size: 13px;
  }
}

/* Additional styling adjustments for different devices */
@media (max-width: 480px) {
  .footer-big {
    padding: 30px 0 20px 0;
  }
  .social-media-links a {
    width: 2.5rem;
    height: 2.5rem;
  }
  .social-media-links a svg {
    font-size: 1.2rem;
  }
}

/* For tablets (768px to 1024px) */
@media (max-width: 1024px) {
  .ft-1 h3 {
    font-size: 1.75rem; /* Reduce font size for tablets */
    padding: 0.4rem 0;  /* Adjust padding */
  }
  .ft-1 p {
    padding: 0rem 2rem 0.8rem 0; /* Reduce padding for tablets */
    font-size: 1rem; /* Adjust paragraph font size */
  }
}

/* For mobile devices (less than 768px) */
@media (max-width: 767px) {
  .ft-1 h3 {
    font-size: 1.5rem; /* Further reduce font size for mobile */
    padding: 0.3rem 0;  /* Adjust padding */
  }
  .ft-1 p {
    padding: 0rem 1rem 0.8rem 0; /* Reduce padding for mobile */
    font-size: 0.9rem; /* Adjust paragraph font size */
  }
}
