.OurStoryPage {
    margin-top: 57px;
    margin-bottom: -2px;
    font-family: 'Arial', sans-serif; 
}
  .OurStoryPage-container {
    display: flex;
    flex-direction: column;
    min-height: 91vh;
    overflow: hidden;
    flex-wrap: wrap;
    gap: 1.5rem; 
  }
  
  .OurStoryPage-container-left,
  .OurStoryPage-container-right {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px; 
  }
  
  .OurStoryPage-container-left {
    background-color: #383838;
    color: #fff;
    text-align: center;
    padding: 2rem;
  }
  
  .OurStoryPage-container-left h1 {
    font-size: 2.5rem;
    margin: 0;
  }
  
  .OurStoryPage-container-right {
    background-color: #f9f9f9;
    padding: 2rem;
    border: 1px solid #ddd; 
  }
  
  .OurStoryPage-text-box {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .OurStoryPage-text-box h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1.5rem;
  }
  
  .OurStoryPage-text-box p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.8rem;
    margin-bottom: 1.5rem;
  }
  
  .aboutUs-img {
    width: 100%;
    max-width: 500px;
    height: auto;
    padding: 2rem;
    border-radius: 10px;
    object-fit: cover; /* Ensure the image covers its container */
    transition: transform 0.3s ease-in-out; /* Smooth zoom effect */
  }
  
  .aboutUs-img:hover {
    transform: scale(1.05); /* Slight zoom effect on hover */
  }
  
  @media screen and (max-width: 560px) {
    .OurStoryPage-container {
      flex-direction: column;
    }
  
    .OurStoryPage-container-left,
    .OurStoryPage-container-right {
      width: 100%;
      padding: 1rem; /* Adjust padding for small screens */
    }
  
    .aboutUs-img {
      width: 100%;
      max-width: none; /* Remove max-width for smaller screens */
    }

}