
  .UnlockChapterModal-close-icon {
    width: 18px;
    float: right;
    margin-top: -2.7rem;
    margin-right: -1.1rem;
    cursor: pointer;
  }
  

  .ProgressModal {
    /* Styles for the modal background */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    
  }
  
  .ProgressModal-content {
    /* Styles for the modal content box */
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
    overflow:auto;

   /* Space for close button positioning */
    /* Allows scrolling within the modal content */
    max-height: 80vh; 
  
  }
  
  .ProgressModal-close-icon {
    /* Styles for the close button icon */
    width: 18px;
    float: right;
    margin-top: -10px;
    margin-right: -1.1rem;
    cursor: pointer;
  }
  
  .ProgressModal-title {
    /* Styles for the modal title */
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .ProgressCard {
    /* Styles for each progress card */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .ProgressCard-info {
    /* Styles for the left section of the card */
    display: flex;
    align-items: center;
  }
  
  .ProgressCard-number {
    /* Styles for the chapter number */
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .ProgressCard-name {
    /* Styles for the chapter name */
    font-size: 16px;
  }
  
  .ProgressCard-actions {
    /* Styles for the right section of the card */
    display: flex;
    align-items: center;
  }
  
  .ProgressCard-progress {
    /* Styles for the progress and icon container */
    display: flex;
    align-items: center;
  }
  
  .ProgressCard-icon {
    /* Styles for the next icon */
    margin-left: 5px;
  }
  

  .ProgressModal::-webkit-scrollbar {
    width: 0;
  }

