.ReadWatchModal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.ReadWatchModal-content {
  position: fixed;
  bottom: 0;
  background-color: #fefefe;
  width: 100%;
  height: 85%;
  /* height was 92% */
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
  border-radius: 8px 8px 0 0;
}

.ReadWatchModal-close-icon {
  width: 18px;
  float: right;
  margin-top: -1.7rem;
  margin-right: 1.2rem;
  cursor: pointer;
}

.ReadWatchModal-title-container {
  width: 60%;
  margin: 1.5rem auto 0 auto;
}
.ReadWatchModal-title-container h1 {
  font-weight: 400;
  margin: 0.7rem 0;
  text-align: center;
}
.ReadWatchModal-title-container button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.5rem 1.8rem;
  border-radius: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
}
.ReadWatchModal-title-container button a {
  text-decoration: none;
  color: #ffffff;
}

.ReadWatchModal-notes-text {
  width: 60%;
  margin: 0 auto;
  font-weight: 400;
  font-size: 0.8rem;
}

.ReadWatchModal-notes-container {
  width: 60%;
  margin: 1.5rem auto;
  font-weight: 500;
  color: grey;
  font-size: 0.8rem;
  border: 1px solid #d1d9d9;
  border-radius: 0.5rem;
  height: 60vh;
  overflow: hidden;
}

.ReadWatchModal-lecture-container {
  width: 60%;
  margin: 1.5rem auto;
  height: 60vh;
  background-color: black !important;
  border-radius: 0.7rem;
  overflow: hidden;
}

.ReadWatchModal-options-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 60%;
  margin: 1.5rem auto;
}

.ReadWatchModal-toggle-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toggle-switch-label {
  font-size: 0.8rem;
  color: grey;
  margin: 0 0.7rem 0 0;
}

.ReadWatchModal-prev-next-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 0.8rem;
  color: grey;
}

.prev-chapter-div,
.next-chapter-div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.prev-chapter-div span {
  margin: 0 0.7rem 0 0.4rem;
}
.next-chapter-div span {
  margin: 0 0.4rem 0 0.7rem;
}

.ReadWatchModal-close-div {
  font-size: 0.8rem;
  color: grey;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ReadWatchModal-close-div span {
  margin-left: 0.3rem;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 27px;
  height: 11px;
}
.toggle-switch input[type='checkbox'] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background-color: #cacaca;
  border-radius: 12px;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: '';
  left: -2px;
  top: -3px;
  width: 17px;
  height: 17px;
  background-color: #959595;
  border-radius: 50%;
  transition: transform 0.3s ease;
}
.toggle-switch input[type='checkbox']:checked + .switch::before {
  transform: translateX(15px);
  background-color: #fea93e;
}
.toggle-switch input[type='checkbox']:checked + .switch {
  background-color: #9c7022;
}

.prev-icon,
.next-icon {
  width: 11px;
}
.esc-icon {
  width: 16px;
}

.video-frame {
  width: 100%;
  border: none;
  height: 100%;
}

.dark-mode {
  color: #ffffff;
  background-color: #383838;
}

/* width */
embed::-webkit-scrollbar {
  width: 3px;
}
/* Track */
embed::-webkit-scrollbar-track {
  background: transparent;
  margin: 2.2rem 0;
}
/* Handle */
embed::-webkit-scrollbar-thumb {
  background-color: #3eac72;
  border-radius: 2px;
}

/* Add Animation */
@-webkit-keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}



.error-image-container {
  display: flex;                /* Use flexbox for centering */
  justify-content: center;      /* Center horizontally */
  align-items: center;          /* Center vertically if needed */
  height: 100%;                 /* Set the container height */
}

.error-image {
  max-width: 100%;             /* Ensures the image doesn't exceed the container's width */
  height: auto;                /* Maintains the aspect ratio of the image */
  margin: 0;                   /* Remove margins */
}

/* Media query for larger screens */
@media (min-width: 941px) {
  .error-image {
    max-width: 600px;          /* Set a max-width for desktop (adjust as needed) */
    margin: -15px auto 20px auto; /* Moves the image up by 20px on desktop */
  }
}
