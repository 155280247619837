.SubscriptionCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: 500;
  border-top: 1px solid #d1d9d9;
  background-color: #ffffff;
}

.SubscriptionCard-left {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 2.5rem 1rem 2.5rem;
}

.SubscriptionCard-right {
  color: #3eac72;
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.9rem 2.5rem 1rem 2.5rem;
}

.unlocked-icons {
  margin-left: 0.4rem;
  width: 12px;
  margin-bottom: -2px;
}

.first-chapter {
  border-top: none;
}

.SubscriptionCard-chapterNo {
  width: 28%;
}
.SubscriptionCard-chapterName {
  width: 72%;
}
