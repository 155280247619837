.btn-1{
    background: linear-gradient(to right, #fb7b49, #edaf38);
    color: aliceblue;
    width: 100%;
    border-radius: 4rem;
   font-weight: 500;
   font-size: large;
    border: none;
    margin-bottom: 0%;
   
}
.close{
    color: azure;
    background-color: red;
    width: 2rem;
   margin-right: 1rem;
   border-radius: 25%;
   border: none;
}
      

.btn-ordinary{

    background: rgb(65, 63, 63);
    color: aliceblue;
    width: 100%;
    border-radius: 4rem;
   font-weight: 500;
   font-size: large;
    border: none;
}
.middle{
    border-color: #edaf38;
    height: 20rem;
    transition: transform 0.3s ease; 
}

.middle:hover {
    transform: scale(1.05); /* Scale up the card on hover */
  }
.ordinary{
    border-color: #000000;

}



.text-middle{
    font-size: large;
    justify-content: center;
    display: flex;
    margin-bottom: 0%;
}

.card-title-middle{
    font-size: xx-large;
    justify-content: center;
    display: flex;
    margin-bottom: 2rem;
}

.badge{
    text-align: center;
    background: #edaf38;
}
.card-header {
    justify-content: center;
    display: flex;
    
}




.model-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto; /* Allow vertical scrolling */
  }
  
  .model {
    background-color: white;
  margin-top: 10px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 100%; /* Limit the width of the model */
    max-height: 100%; /* Limit the height of the model */
    overflow: hidden; /* Allow vertical scrolling within the model */
  }
  