.EditProfilePage {
  margin-top: 56px;
}

.EditProfilePage-title-container {
  background-color: #383838;
  color: #ffffff;
  padding: 3rem 0 9rem 0;
}
.EditProfilePage-title-container h1 {
  width: 70%;
  text-align: center;
  margin: 0 auto;
  font-size: 2.3rem;
  font-weight: 400;
}

.EditProfile-card-container {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.EditProfile-card {
  background-color: #ffffff;
  border: 1px solid #d1d9d9;
  border-radius: 0.5rem;
  padding: 1rem 0 2rem 0;
  margin-bottom: 1rem;
}
.EditProfile-card h3 {
  font-weight: 400;
  width: 65%;
  margin: 1rem auto;
}

.EditProfile-card-content-div {
  width: 65%;
  margin: 0 auto;
}

.EditProfile-card-content-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.EditProfile-card-content-col {
  width: 50%;
}
.EditProfile-card-content-col p {
  font-size: 0.8rem;
  margin: 0.6rem 0;
  color: grey;
  font-weight: 500;
}
.EditProfile-card-content-col input {
  width: 80%;
  border: 1px solid #d1d9d9;
  padding: 0.8rem 1rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.EditProfile-card-content-col input:focus {
  outline: none;
}

.address-col {
  width: 100%;
}

.address-col .address-input {
  width: 90%;
}

.first-card {
  margin-top: -6rem;
}

.EditProfile-button-container {
  width: 70%;
  margin: 0 auto 5rem auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.save-button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.7rem 0rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-weight: 500;
  width: 16%;
  margin-left: 0.6rem;
}

.cancel-button {
  border: 1px solid #ffb63e;
  cursor: pointer;
  color: #f5943c;
  background: #ffffff;
  padding: 0.7rem 0;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-weight: 500;
  width: 16%;
  margin-right: 0.6rem;
}

@media screen and (max-width: 560px) {
  .EditProfile-card-container {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 3rem;
  }
  .EditProfile-card h3 {
    font-weight: 400;
    width: 90%;
    margin: 1rem auto;
  }
  .EditProfile-card-content-div {
    width: 90%;
    margin: 0 auto;
  }
  .EditProfile-card-content-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin-bottom: 1.5rem;
  }
  .EditProfile-card-content-col {
    width: 100%;
  }
  .EditProfile-card-content-col input {
    width: 100%;
    border: 1px solid #d1d9d9;
    padding: 0.8rem 1rem;
    border-radius: 2rem;
    font-size: 0.8rem;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }
  .EditProfile-button-container {
    width: 90%;
    margin: 0 auto 5rem auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .save-button {
    border: none;
    cursor: pointer;
    color: #ffffff;
    background: linear-gradient(to right, #fb794a, #edb038);
    padding: 0.7rem 0rem;
    border-radius: 2rem;
    font-size: 0.8rem;
    font-weight: 500;
    width: 40%;
    margin-left: 0.6rem;
  }

  .cancel-button {
    border: 1px solid #ffb63e;
    cursor: pointer;
    color: #f5943c;
    background: #ffffff;
    padding: 0.7rem 0;
    border-radius: 2rem;
    font-size: 0.8rem;
    font-weight: 500;
    width: 40%;
    margin-right: 0.6rem;
  }
}
