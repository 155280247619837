.TryModal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-animation-name: loginFadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: loginFadeIn;
  animation-duration: 0.4s;
  color: white;
}

.TryModal-content {
  position: fixed;
  right: 0;
  background-color: #383838;
  width: 100%;
  height: 100%;
  -webkit-animation-name: loginSlideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: loginSlideIn;
  animation-duration: 0.4s;
  text-align: center;
}

.TryModal-close-icon {
  position: absolute;
  margin-left: 20px;
  margin-top: 20px;
  left: 0;
  width: 18px;
  cursor: pointer;
}

.TryModal-container {
  margin: 0 auto;
  text-align: left;
}

.TryModal-title {
  font-weight: 400;
  margin: 4rem 0 1.3rem 1.5rem;
  font-size: 1.6rem;
}

.TryModal-menu-1-item {
  padding: 0.6rem 0 0.6rem 1.5rem;
  font-size: 0.8rem;
  margin: 0;
  cursor: pointer;
  border-radius: 2rem 0 0 2rem;
}
.TryModal-menu-1-item:hover {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  color: #ffffff;
}

.active-item {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  color: #ffffff;
}

.TryModal-menu-2 {
  margin-left: 1.5rem;
  padding: 0.6rem 0;
}

.no-subject-item {
  font-size: 0.8rem;
  padding: 0.6rem 0 0.6rem 1.5rem;
  cursor: auto;
}

@media screen and (max-width: 560px) {
  .active-item {
    background: none;
    color: #ffffff;
  }
}
