
/* Base styles for Navbar */
.Navbar {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  background-color: #383838;
  color: #ffffff;
  height: 56px;
  z-index: 1;
  width: 100%;
}

.Navbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.nav-link {
  padding: 0;
  text-decoration: none;
  color: #ffffff;
  margin-right: 15px;
}

.nav-link:hover {
  color: #ffb53e;
  border-bottom: 4px solid #ffb53e;
}

.Navbar-container-left,
.Navbar-container-right {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
}

.Navbar-container-right {
  width: 30%;
  justify-content: space-around;
}

.try-free-button {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  outline: none;
  border: none;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 2rem;
}

.try-free-button:hover {
  transform: scale(1.02);
  transition: 0.3s ease;
}

.Navbar-items {
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem 1rem;
  margin: 0;
}

.activ {
  border-bottom: 4px solid #ffb53e;
  color: #ffb53e;
}

.course-div {
  position: relative;
}

.course-menu-1 {
  background-color: #ffffff; /* White background */
  padding: 0.6rem 0;
  color: black;
  border-radius: 0 0 8px 8px;
}
/* .course-menu-2 */
.course-menu-1 {
  position: absolute;
  top: 60px;
  left: 0;
  width: 200px;
}

.course-menu-1 div {
  padding: 0.6rem 0 0.6rem 2rem;
  font-size: 0.8rem;
  margin: 0;
  border-radius: 2rem 0 0 2rem;
}

.course-menu-1-item:hover {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  color: #ffffff;
  cursor: pointer;
}

.active-item {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  color: #ffffff;
}

  .course-menu-2 {
  position: absolute;
  left: 208px;
  top: 1px;
  width: 200px;
  background-color: #ffffff;
  padding: 0.6rem 0;
  color: black;
  border-radius: 0 0 8px 8px !important;
  padding-left: 0 !important;
}

.dim {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 56px;
  left: 0;
}

.down-icon {
  width: 13px;
  margin-left: 0.3rem;
  margin-bottom: -1px;
}

.profile-div {
  position: relative;
  padding-left: 4px;
  padding-right: 4px;
}

.profile-menu {
  position: absolute;
  top: 58px;
  left: 2px;
  width: 190px;
  background-color: #ffffff;
  padding: 0.6rem 0;
  color: black;
  border-radius: 0 0 8px 8px;
  border: 1px solid #d1d9d9;
}

.profile-menu div {
  font-size: 0.8rem;
  margin: 0;
  border-radius: 2rem 0 0 2rem;
}

.profile-menu div p {
  margin: 0;
  padding: 0.6rem 0 0.6rem 2rem;
}

.name-text {
  padding-top: 11%;
}

.course-text {
  padding-top: 20%;
}

.ec-logo {
  margin-left: 5%;
  margin-right: 5%;
  max-width: 65px;
  cursor: pointer;
}

.no-subject-item {
  cursor: auto;
}

.bar {
  display: none;
}

.nav_left {
  display: flex;
  flex-direction: row;
}

.remove {
  display: flex;
  flex-direction: row;
}

.sec {
  display: flex;
  flex-direction: row;
}

.sec_shrink {
  display: flex;
  flex-direction: row;
}

.j {
  display: none;
}

.new_nav {
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between;
}

.hide_vertical_nav {
  display: none;
}

.show_vertical_nav {
  width: 100%;
  height: 100vh;
  display: block;
  position: absolute;
  left: 0;
  top: 56px;
  transition: all 0.5s ease-in;
  background-color: #383838;
  font-size: small;
  overflow-y: auto;
}

.nav_list {
  padding-top: 10%;
  padding-right: 10%;
  list-style: none;
}

.nav_list_item {
  padding: 4px;
  padding-left: 20px;
  font-size: 14px;
}

.nav_list_item:hover {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  border-radius: 3rem 0 0 2rem;
}

.dropdown {
  position: relative;
}

.dropdown ul {
  display: relative;
  list-style: none;
}

.drop_list_item {
  padding: 6px;
}

.openProfileTabs {
  border-bottom: 2px solid #ffb53e;
}

.profileTabs-list:active {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  border-radius: 2rem 0 0 2rem;
}

.color {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  border-radius: 2rem 0 0 2rem;
}

.vv {
  padding-bottom: 6px;
}

.lev3_item:hover {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  border-radius: 2rem 0 0 2rem;
}

.lev3_item {
  padding: 3px;
}

.box_list li {
  transform-origin: 50% 0%;
  transform: perspective(350px) rotateX(-90deg);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.box_list:hover li {
  transform: perspective(350px) rotateX(0deg);
  transition: 0.2s linear 0s;
}

.stop-scroll {
  height: 100%;
  overflow-y: hidden !important;
}

/* Tablet-specific styles */


/* Tablet screen adjustments */
/* Tablet screen adjustments */
/* Tablet screen adjustments */
/* Tablet screen adjustments */
/* Tablet screen adjustments */
@media (min-width: 768px) and (max-width: 1024px) {
  .Navbar-container {
    padding: 0 10px; /* Reduced padding for better fit */
    display: flex;
    justify-content: space-between; /* Distribute space between left and right sections */
    flex-wrap: nowrap; /* Prevent wrapping of items */
  }

  .Navbar-container-left,
  .Navbar-container-right {
    display: flex;
    flex-direction: row;
    align-items: center ; /* Center align items vertically */
  }

  .Navbar-container-left {
    flex: 1; /* Allow left container to take available space */
  }

  .Navbar-container-right {
    flex: 1; /* Allow right container to take available space */
    justify-content: flex-end; /* Align items to the end */
  }

  .nav-link, .try-free-button {
    margin-right: 6px; /* Further reduce margin for tighter spacing */
    font-size: 0.8rem; /* Reduce font size for better fit */
    display: inline-block; /* Ensure items are inline */
  }

  .try-free-button {
    padding: 0.3rem 0.6rem; /* Adjust padding for a better fit */
    background: linear-gradient(to right, #fb7b49, #edaf38); /* Button background */
    color: #ffffff; /* Text color */
    border: none; /* Remove border */
    border-radius: 50px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
  }

  .try-free-button:hover {
    background: linear-gradient(to right, #edaf38, #fb7b49); /* Inverted gradient on hover */
  }

  .Navbar-items {
    padding: 0.4rem 0.5rem; /* Adjust padding for navbar items */
    font-size: 0.8rem; /* Reduce font size for consistency */
  }

  /* Ensure all important items are displayed */
  .Navbar-container-left, .Navbar-container-right {
    flex-wrap: nowrap; /* Prevent wrapping */
    align-items: normal; /* Center items vertically */
  }

  .Navbar-items {
    display: inline-block; /* Ensure items are inline-block for better fit */
  }
}



/*.Navbar {
  position: fixed;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0;
  background-color: #383838;
  color: #ffffff;
  height: 56px;
  z-index: 1;
  width: 100%;
}

.Navbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.nav-link {
  padding: 0;
  text-decoration: none;
  color: #ffffff;
  margin-right: 15px;
}

.nav-link:hover {
  color: #ffb53e;
  border-bottom: 4px solid #ffb53e;
}

.Navbar-container-left,
.Navbar-container-right {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
}

.Navbar-container-right {
  width: 30%;
  justify-content: space-around;
}

.try-free-button {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  outline: none;
  border: none;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 2rem;
}

.try-free-button:hover {
  transform: scale(1.02);
  transition: 0.3s ease;
}

.Navbar-items {
  font-size: 1rem;
  cursor: pointer;
  padding: 1rem 1rem;
  margin: 0;
}

.activ {
  border-bottom: 4px solid #ffb53e;
  color: #ffb53e;
}

.course-div {
  position: relative;
}

.course-menu-1 {
  background-color: #ffffff;
  padding: 0.6rem 0;
  color: black;
  border-radius: 0 0 8px 8px;
}

.course-menu-1 {
  position: absolute;
  top: 60px;
  left: 0;
  width: 200px;
}

.course-menu-1 div {
  padding: 0.6rem 0 0.6rem 2rem;
  font-size: 0.8rem;
  margin: 0;
  border-radius: 2rem 0 0 2rem;
}

.course-menu-1-item:hover {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  color: #ffffff;
  cursor: pointer;
}

.active-item {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  color: #ffffff;
}

.course-menu-2 {
  position: absolute;
  left: 208px;
  top: 1px;
  width: 200px;
  background-color: #ffffff;
  padding: 0.6rem 0;
  color: black;
  border-radius: 0 0 8px 8px !important;
  padding-left: 0 !important;
}

.dim {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 56px;
  left: 0;
}

.down-icon {
  width: 13px;
  margin-left: 0.3rem;
  margin-bottom: -1px;
}

.profile-div {
  position: relative;
  padding-left: 4px;
  padding-right: 4px;
}

.profile-menu {
  position: absolute;
  top: 58px;
  left: 2px;
  width: 190px;
  background-color: #ffffff;
  padding: 0.6rem 0;
  color: black;
  border-radius: 0 0 8px 8px;
  border: 1px solid #d1d9d9;
}

.profile-menu div {
  font-size: 0.8rem;
  margin: 0;
  border-radius: 2rem 0 0 2rem;
}

.profile-menu div p {
  margin: 0;
  padding: 0.6rem 0 0.6rem 2rem;
}

.name-text {
  padding-top: 11%;
}

.course-text {
  padding-top: 20%;
}

.ec-logo {
  margin-left: 5%;
  margin-right: 5%;
  max-width: 65px;
  cursor: pointer;
}

.no-subject-item {
  cursor: auto;
}

.bar {
  display: none;
}

.nav_left {
  display: flex;
  flex-direction: row;
}

.remove {
  display: flex;
  flex-direction: row;
}

.sec {
  display: flex;
  flex-direction: row;
}

.sec_shrink {
  display: flex;
  flex-direction: row;
}

.j {
  display: none;
}

.new_nav {
  display: flex !important;
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between;
}

.hide_vertical_nav {
  display: none;
}

.show_vertical_nav {
  width: 100%;
  height: 100vh;
  display: block;
  position: absolute;
  left: 0;
  top: 56px;
  transition: all 0.5s ease-in;
  background-color: #383838;
  font-size: small;
  overflow-y: auto;
}

.nav_list {
  padding-top: 10%;
  padding-right: 10%;
  list-style: none;
}

.nav_list_item {
  padding: 4px;
  padding-left: 20px;
  font-size: 14px;
}

.nav_list_item:hover {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  border-radius: 3rem 0 0 2rem;
}

.dropdown {
  position: relative;
}

.dropdown ul {
  display: relative;
  list-style: none;
}

.drop_list_item {
  padding: 6px;
}

.openProfileTabs {
  border-bottom: 2px solid #ffb53e;
}

.profileTabs-list:active {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  border-radius: 2rem 0 0 2rem;
}

.color {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  border-radius: 2rem 0 0 2rem;
}

.vv {
  padding-bottom: 6px;
}

.lev3_item:hover {
  background: linear-gradient(to right, #fb7b49, #edaf38);
  border-radius: 2rem 0 0 2rem;
}

.lev3_item {
  padding: 3px;
}

.box_list li {
  transform-origin: 50% 0%;
  transform: perspective(350px) rotateX(-90deg);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.box_list:hover li {
  transform: perspective(350px) rotateX(0deg);
  transition: 0.2s linear 0s;
}

.stop-scroll {
  height: 100%;
  overflow-y: hidden !important;
}*/







/* Mobile-specific styles */
@media screen and (max-width: 765px) {
  .bar {
    display: block;
    margin-right: 10px;
    padding-left: 5%;
    width: 30px;
    height: 30px;
  }

  .nav_left {
    display: flex;
    flex-direction: column;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
    left: 0;
    position: absolute;
    margin-top: 50px;
    width: 100vw;
    background-color: #383838;
  }

  .body {
    background-color: #383838;
  }

  .remove {
    display: flex;
    flex-direction: column;
    width: 100%;
    left: -100%;
    transition: all 0.5s ease-in-out;
  }

  .sec {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .sec_shrink {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .show_vertical_nav {
    width: 100%;
    height: 100vh;
    display: block;
    position: absolute;
    left: 0;
    top: 56px;
    transition: all 0.5s ease-in;
    background-color: #383838;
    font-size: small;
    overflow-y: auto;
  }
}
