.custom-card {
  width: 20rem;
  border: 1px solid #7b7878;
  border-radius: 35px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth hover effect */
}

.custom-card:hover {
  transform: translateY(-10px); /* Slightly lift the card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

.custom-card_no_meet {
  width: 15rem;
  border: 1px solid #e0e0e0;
  border-radius: 35px;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for smooth hover effect */
}

.custom-card_no_meet:hover {
  transform: translateY(-10px); /* Slightly lift the card on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Darker shadow on hover */
}

.custom-flex {
  display: flex;
}

.custom-image {
  width: 100%;
  height: 100%;
  flex: 0 0 31%;
  overflow: hidden;
  margin: 0;
  padding: 0%;
}

.custom-img {
  width: 98%;
  height: 100%;
  object-fit: cover;
  border-radius: 25%;
}

.custom-body {
  padding-left: 1rem;
  padding-top: 1%;
}

.custom-date {
  font-size: 12px;
  color: #131415;
  margin-bottom: 2px;
  font-weight: 700;
}

.custom-title {
  font-size: 20px;
  color: #2fb05e;
  margin-top: 0;
  padding-top: 0%;
  margin-bottom: 5px;
  font-weight: 600;
}

.custom-text {
  font-weight: bolder;
  font-size: 14px;
  padding-top: 0px;
  margin: 0 0 0 0;
}

.custom-class {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 0px;
}

.custom-btn {
  display: flex;
  background-color: #180882;
  color: #fff;
  border-radius: 20px;
  text-decoration: none;
  margin-bottom: 8px;
  height: 2rem;
  align-items: center;
  justify-content: center; /* Horizontally centering */
  text-align: center;
  width: 8rem;
  transition: background-color 0.3s ease; /* Add transition for background color */
}

.custom-btn:hover {
  background-color: #120661; /* Darken background color on hover */
}

.cancel-btn {
  display: flex;
  padding: 8px 20px;
  background-color: #fc2c2c;
  color: #fff;
  border-radius: 20px;
  text-decoration: none;
  margin-bottom: 6px;
  height: 2rem;
  justify-items: center;
  align-items: center;
  transition: background-color 0.3s ease; /* Add transition for background color */
}

.cancel-btn:hover {
  background-color: #e62020; /* Darken background color on hover */
}

.input {
  height: 20px;
  width: 10rem;
}

.container {
  width: 100%;
  margin-top: 6rem;
}
