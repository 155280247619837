


.invoice-table {
    color: white !important;
    background: linear-gradient(to right, #fb794a, #edb038) !important;
    border: 1px solid black !important;
  }
  
  
  /* .invoice-table {
    color: white;
    background:linear-gradient(to right, #fb794a, #edb038);
    border: 1px solid black;
  } */
  
  .invoice-table th {
  
    font-size: 14px;
    text-align: start;
    padding: 10px;
    color: white !important;
    background: linear-gradient(to right, #fb794a, #edb038) !important;
    border: 1px solid black !important;
  }
  
  .invoice-table h5 {
    font-size: inherit;
  }
  .invoice-temp-container {
    width: "40%";
    height: "50%";
  }