/* Zoom.css */
.room-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f8f9fa, #e9ecef);
  text-align: center;
  padding: 20px;
}

h1 {
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 30px;
}

.fancy-btn {
  font-size: 1.25rem;
  padding: 15px 35px !important;
  border: none;
  border-radius: 50px !important;
  background-color: #ffb53e !important; /* Yellow-orange gradient */
  color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  
}

.fancy-btn:hover {
  background-color: #ff9900;
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
  color: white !important;
}

#zmmtg-root {
  display: none; /* Hide Zoom container until meeting starts */
}

.image-container {
  margin-bottom: 20px;
}

.image-container img {
  max-width: 100%;
  
  height: 200px; /* Set the desired height */
  object-fit: cover; /* Ensure the image scales properly */
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
