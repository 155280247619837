.ProfilePage {
  margin-top: 56px;
}

.ProfilePage-title-container {
  background-color: #383838;
  color: #ffffff;
  padding: 3rem 0 9rem 0;
}

.ProfilePage-title-row {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.ProfilePage-title-row h1 {
  font-size: 2.3rem;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.7rem;
  color: #ffffff;
}
/* .ProfilePage-title-row span {
  font-size: 0.8rem;
  color: #3eac72;
  cursor: pointer;
} */

.editProfileSpan {
  font-size: 0.8rem;
  color: #3eac72;
  cursor: pointer;
}

.viewProgress {
  font-size: 1rem;
  color: #3eac72;
  cursor: pointer;
}

.contact-details-div {
  width: 70%;
  margin: 0 auto;
  font-size: 0.8rem;
  font-weight: 300;
}

.profile-name {
  padding-right: 0.5rem;
  margin-right: 0.5rem;
  border-right: 1px solid #ffffff;
}

.Profile-card-container {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 5rem;
}

.Profile-card {
  background-color: #ffffff;
  border: 1px solid #d1d9d9;
  border-radius: 0.5rem;
  padding: 1rem 0 2.5rem 0;
  margin-bottom: 1rem;
}
.Profile-card h3 {
  font-weight: 400;
  width: 65%;
  margin: 0.2rem ;
  text-align: left; 
  padding-left: 4rem; /* Add padding to move the text slightly to the right *//* Ensure it's aligned left */
}

.Profile-card-content-div {
  width: 65%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.Profile-card-column-div {
  display: flex;
  flex-direction: column;
}
.Profile-card-column-div span {
  font-size: 0.8rem;
  margin: 0.4rem 0;
  color: grey;
  font-weight: 500;
  line-height: 1.1rem;
}

.col-values-div {
  width: 40%;
  text-align: right;
}
.col-values-div span {
  color: black;
}

.first-card {
  margin-top: -6rem;
}
.styled-button {

  background: linear-gradient(45deg, #FF7E5F, #FEB47B);
  color: white;
  border: 1px solid #FF7E5F;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  display:inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;  
  margin: 0 0.5rem;
  transform: translateX(-10px); /* Initial left position for animation */
  animation: slideIn 0.3s ease-out forwards;
  opacity: 0;
  
}

.styled-button:hover {

  background: linear-gradient(45deg, #FEB47B, #FF7E5F);
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */


}

.forward-icon {
  margin-left: 8px; /* Add space between text and icon */
  
}


 .ttton {
  background: #383838;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background 0.3s ease;
    margin-right: 13px; /* Adjust the value as needed */
}


 .ttton:hover {
  background: #383838;;
}



@media screen and (max-width: 560px) {
  .Profile-card h3 {
    font-weight: 600;
    font-size: 16px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 1rem;
  }
  
  
  .Profile-card-container {
    width: 90%;
    margin: 0 auto;
    margin-bottom: 5rem;
  }

  .Profile-card-content-div {
    width: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .col-values-div {
    width: 70%;
    text-align: right;
    padding-right: 2px;
  }

  .ProfilePage-title-row {
    width: 90%;
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0 auto;
  }

  .contact-details-div {
    width: 90%;
    margin: 0 auto;
    font-size: 0.8rem;
    font-weight: 300;
  }

  .editProfileSpan {
    font-size: 0.8rem;
    color: #3eac72;
    cursor: pointer;
  }
  
}