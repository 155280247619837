.SubmissionModal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.SubmissionModal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  width: 50%;
  border-radius: 10px;
  text-align: center;
  max-height: 90vh;
  overflow: auto;
}
.SubmissionModal-content::-webkit-scrollbar {
  width: 0;
}
.SubmissionModal-content h1 {
  font-weight: 400;
  margin-bottom: 2rem;
}

.dropzone {
  text-align: center;
  padding: 1rem;
  border: 3px dashed #eeeeee;
  background-color: #fafafa;
  color: #bdbdbd;
  margin-bottom: 0.6rem;
  cursor: pointer;
}

.SubmissionModal-input-container {
  width: 80%;
  margin: 0 auto;
}

.SubmissionModal-input-text {
  text-align: left;
  margin-bottom: 0.2rem;
  font-size: 0.8rem;
  color: grey;
}

.selected-files-div {
  text-align: left;
  font-size: 0.9rem;
  color: grey;
  margin-bottom: 3rem;
}
.selected-files-div span {
  margin-right: 0.7rem;
}

.SubmissionModal-button-div {
  width: 60%;
  margin: 0 auto 2rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.SubmissionModal-button-div button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.submitted-text {
  margin: 5rem 1rem;
  font-style: italic;
}

/* View submission modal styles*/

.viewsubmission-container {
  margin: 1rem;
  margin-bottom: 2rem;
}

.viewsubmission-input-container {
  width: 90%;
  margin: 0 auto;
}

.viewsubmission-input-text {
  text-align: left;
  margin-bottom: 0.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  color: grey;
}

.viewsubmission-documentsDiv {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1.6rem;
}
.viewsubmission-documentsDiv .submissionLinks {
  width: 20%;
  text-align: left;
  margin: 0 1rem;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  font-weight: 600;
}
.viewsubmission-documentsDiv .submissionLinks:hover {
  color: #4963e1;
}

.viewsubmission-headDiv {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.viewsubmission-headDiv h1 {
  margin-left: auto;
  font-weight: 400;
  margin-bottom: 1rem;
}

.viewsubmission-button {
  margin-left: auto;
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.5rem 2rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-weight: 500;
}
