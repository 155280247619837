.EventCard {
  width: 65%;
  margin: 1rem auto;
  padding: 1.5rem 2rem 2rem 2rem;
  border: 1px solid #d1d9d9;
  border-radius: 0.5rem;
  background-color: #ffffff;
}

.EventCard-title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.9rem;
  margin-top: 0.3rem;
}

.EventCard-amount-text {
  font-size: 0.8rem;
  color: grey;
  font-weight: 500;
}

.EventCard-content-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.EventCard-content-row img {
  width: 22%;
  height: 100px;
  border-radius: 8px;
  margin-right: 1.5rem;
}

.EventCard-content-div {
  width: 75%;
}
.EventCard-content-div p {
  font-size: 0.8rem;
  color: grey;
  width: 60%;
  line-height: 1.3rem;
  margin: -1px 0 0.8rem 0;
}

.register-event-button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-weight: 500;
}

.EventCard-date-row {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.EventCard-date-text {
  font-size: 0.8rem;
  margin-right: 2rem;
  font-weight: 500;
}

.EventCard-details-text {
  font-size: 0.8rem;
  color: #3eac72;
  font-weight: 500;
}

.EventCard-detail-div {
  cursor: pointer;
}
.EventCard-detail-div img {
  width: 11px;
  height: 11px;
  margin: 0 0 -1px 5px;
}
.bb {
  display: none;
}
@media screen and (max-width: 560px) {
  .EventCard {
    width: 90%;
    margin: 1rem auto;
    padding: 1rem 1rem 2rem 1rem;
    border: 1px solid #d1d9d9;
    border-radius: 0.5rem;
    background-color: #ffffff;
  }
  .EventCard-title-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.9rem;
    margin-top: 0.3rem;
  }
  .EventCard-content-row {
    display: flex;
    flex-direction: column;
  }
  .EventCard-content-row .main {
    width: 300px;
    height: 180px !important;
    border-radius: 8px;
    margin-right: 0;
  }
  .EventCard-content-div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .EventCard-content-div p {
    font-size: 0.8rem;
    color: grey;
    width: 100%;
    line-height: 1.3rem;
    margin: -1px 0 0 0;
  }
  .EventCard-date-row {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .EventCard-date {
    margin-bottom: 1rem;
  }
  .register-event-button {
    width: 70%;
    border: none;
    cursor: pointer;
    color: #ffffff;
    background: linear-gradient(to right, #fb794a, #edb038);
    padding: 0.6rem 1.5rem;
    border-radius: 2rem;
    font-size: 0.8rem;
    font-weight: 500;
  }
  .EventCard-detail-div {
    cursor: pointer;
    margin-top: 1.2rem;
    text-align: end;
  }
  .EventCard-title-text {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
  }
  .EventCard-title-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.9rem;
    margin-top: 1.5rem;
  }
}
