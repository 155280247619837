.ProjectCard {
    width: 65%;
    margin: 1rem auto;
    padding: 1.5rem 2rem 1.2rem 2rem;
    border: 1px solid #d1d9d9;
    border-radius: 0.5rem;
    background-color: #ffffff;
  }
  
  .ProjectCard-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  
  .ProjectCard-amount-text {
    font-size: 0.8rem;
    color: grey;
    font-weight: 500;
  }
  
  .ProjectCard-content-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .ProjectCard-content-row img {
    width: 160px;
    height: 90px;
    border-radius: 8px;
    margin-right: 1.5rem;
  }
  
  .ProjectCard-img-container {
    width: 23%;
  }
  
  .ProjectCard-content-div {
    width: 77%;
  }
  
  .ProjectCard-date-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  
  .ProjectCard-date-text {
    font-size: 0.8rem;
    margin-right: 2rem;
  }
  
  .ProjectCard-details-text {
    font-size: 0.8rem;
    color: #3eac72;
    font-weight: 500;
  }
  
  .ProjectCard-detail-div {
    margin-top: 1.3rem;
    margin-bottom: 0.7rem;
    cursor: pointer;
  }

  .ProjectCard-detail-div img {
    width: 11px;
    height: 11px;
    margin: 0 0 -1px 5px;
  }
  
  /* img.ProjectCard-forward-icon {
    width: 11px;
    height: 11px;
    margin: 0 0 -1px 5px;
  } */
  
  .project-text {
    width: 65%;
    font-size: 0.8rem;
    color: grey;
    line-height: 1.3rem;
    margin: -1px 0 0.8rem 0;
  }

  .register-project-button{
    border: none;
    cursor: pointer;
    color: #ffffff;
    background: linear-gradient(to right, #fb794a, #edb038);
    padding: 0.5rem 1.5rem;
    border-radius: 2rem;
    font-size: 0.8rem;
    font-weight: 500;
  }
  
  @media screen and (max-width: 560px) {
    .ProjectCard-content-row {
      flex-direction: column;
      width: 100%;
    }
    .ProjectCard {
      width: 90%;
    }
  
    .ProjectCard-title-text {
      font-size: 18px;
      font-weight: 600;
      margin-top: 1rem;
    }
  
    .ProjectCard-img-container {
      width: 100%;
    }
  
    .ProjectCard-content-div {
      width: 100%;
    }
    .main {
      max-width: 100%;
      max-height: 100%;
      width: auto !important;
      height: auto !important;
    }
    .project-text {
      width: 100%;
      font-size: 0.8rem;
      color: grey;
      line-height: 1.3rem;
    }
    .ProjectCard-detail-div {
      text-align: right;
      margin-top: 0;
    }
  }
  