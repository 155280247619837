.ErrorPage {
  background-color: #383838;
  color: #ffffff;
  text-align: center;
  margin-top: 57px;
}

.ErrorPage-heading {
  font-weight: 400;
  font-size: 2.1rem;
  margin-bottom: 0.9rem;
  padding-top: 5rem;
}

.ErrorPage-text {
  width: 55%;
  margin: 0 auto;
  font-size: 0.8rem;
  opacity: 0.8;
  line-height: 1.3rem;
}

.error-img {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 25%;
}

@media screen and (max-width: 560px) {
  .ErrorPage-heading {
    width: 90%;
    margin: auto;
    margin-bottom: 1rem;
    font-size: 32px;
  }

  .ErrorPage-text {
    width: 90%;
    font-size: 14px;
  }

  .error-img {
    width: 80%;
  }
}
