.EventWorkshopModal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.EventWorkshopModal-content {
  position: fixed;
  bottom: 0;
  background-color: #fefefe;
  width: 100%;
  height: 92%;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
  border-radius: 8px 8px 0 0;
  overflow: auto;
}
.EventWorkshopModal-content::-webkit-scrollbar {
  width: 0;
}

.EventWorkshopModal-close-icon {
  width: 18px;
  position: fixed;
  top: 4%;
  right: 0.8rem;
  cursor: pointer;
}

.EventWorkshopModal-container {
  width: 70%;
  height: 70%;
  margin: 0 auto;
}

.EventWorkshopModal-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
}
.EventWorkshopModal-title-row h1 {
  font-weight: 400;
  margin: 0;
}
.EventWorkshopModal-title-row button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.registration-text {
  font-size: 0.9rem;
  margin-top: 0.7rem;
}

.EventWorkshopModal-desc {
  width: 45%;
  height: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3rem;
  color: #777777;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.EventWorkshopModal-img-desc-div {
  width: 100%;
  height: 435px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.EventWorkshopModal-imgDiv {
  width: 45%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.EventWorkshopModal-img {
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
}

.registration-details-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.registration-details-div button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.date-text-margin {
  margin-right: 2rem;
}

.EventWorkshopModal-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 1.5rem auto;
}
.EventWorkshopModal-button-container button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.5rem 1.5rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-weight: 500;
}
@media screen and (max-width: 560px) {
  .EventWorkshopModal-container {
    width: 90%;
    height: 70%;
    margin: 0 auto;
  }

  .EventWorkshopModal-img-desc-div {
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  .EventWorkshopModal-desc {
    width: 100%;
    height: 98%;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3rem;
    color: #777777;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .EventWorkshopModal-imgDiv {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .EventWorkshopModal-img {
    width: 100%;
    height: 100%;
    border-radius: 0.8rem;
  }

  .registration-details-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
