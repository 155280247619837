.UnlockChapterModal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.7);
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.UnlockChapterModal-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #FFDAB9;
  width: 92%;
  height: 58%;
  animation-name: slideIn;
  animation-duration: 0.4s;
  border-radius: 10px;
  padding: 1.1rem 1.1rem 0.8rem 1rem;
  /* Center content vertically and horizontally */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Center-align text */
  
}



.styled-button,
.styled-button-right {
  background: linear-gradient(45deg, #FF7E5F, #FEB47B);
  color: white;
  border: 1px solid #FF7E5F;
  border-radius: 30px;
  padding: 0.5rem 1rem;
  font-size: 14px;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  display:inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;  
  margin: 0 0.5rem;
  transform: translateX(-10px); /* Initial left position for animation */
  animation: slideIn 0.3s ease-out forwards;
  opacity: 0;
  
}

.styled-button:hover,
.styled-button-right:hover {
  background: linear-gradient(45deg, #FEB47B, #FF7E5F);
  transform: scale(1.05);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}


.modal-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-top: 2rem;
  padding-left: 2rem; 
  
}

.UnlockChapterModal-content-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.unlock-chapter-img {
  width: 17%;
  border-radius: 10px;
}

.UnlockChapterModal-text-container {
  width: 25.6%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.UnlockChapterModal-text-container h1 {
  font-family: Smoothy, Arial, serif;
  font-size: 2.2rem;
  font-weight: 500;
  margin: 0;
}

.UnlockChapterModal-text-container p {
  font-size: 0.8rem;
  font-weight: 400;
  width: 90%;
  line-height: 1.1rem;
  margin: 0 auto;
}

.UnlockChapterModal-details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 85%;
  margin: 1rem auto;
}

.UnlockChapterModal-details-container-col-left,
.UnlockChapterModal-details-container-col-right {
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.UnlockChapterModal-details-container-col-left {
  text-align: left;
  color: grey;
}

.UnlockChapterModal-details-container-col-right {
  text-align: right;
}

.UnlockChapterModal-button {
  width: 55%;
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.5rem 0;
  border-radius: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  margin: 0.5rem 0;
}

.UnlockChapterModal-close-icon {
  width: 18px;
  float: right;
  margin-top: -2.7rem;
  margin-right: -1.1rem;
  cursor: pointer;
}

.UnlockChapterModal-link {
  text-decoration: none;
  color: #ffffff;
}

.hover-image {
  display: none;
  position: absolute;
  top: -20px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 20px; /* Adjusted width for a smaller image */
  height: 20px; /* Maintain aspect ratio */
  
 
}

.styled-button-right:hover .hover-image {
  display: block;
  z-index: 1;
}

.congrats-message {
  display: none;
  position: absolute;
  top: -30px; /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  font-size: 12px;
  z-index: 2; 
}

.styled-button-right:hover .congrats-message {
  display: block;
}

.congrats-text {
  font-size: 18px;
  color: green;
  margin-top: 10px;
  font-weight: bold;
}

.points-description {
  font-size: 14px;
  color: #555;
  margin-top: 5px;
}

.h2 .p {
  color: white;
}

.styled-button-right {
  transition: opacity 0.3s ease;
  margin-top: 0;
  position: relative;
}

.styled-button-right:hover {
  opacity: 0.7;
}

.styled-button:hover ~ .styled-button-right {
  opacity: 0;
  visibility: hidden;
}
/* When you hover over Use Points button, hide sibling buttons */
.styled-button:hover ~ .styled-button-right {
  display: none;
}

/* Parent container for centering the content */
.reward-selection-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Makes the heading and button stack vertically */
  height: 50vh; /* Full height to center vertically */
}

/* Heading alignment */
.reward-selection-modal h1 {
  text-align: center;
  margin-bottom: 100px;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

/* Button alignment */
.reward-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Adds space between buttons */
  padding-top: 30px;
}

/* Styling the anime image temporarily for 1 minute 
.anime-image {
  width: 50px; /* Adjust width 
  height: 50px;
  margin-left: 10px;
}*/

/* Circular image */
.circular-image {

  border-radius: 100%; /* This makes the image circular */
  object-fit: cover;
 justify-items: center;
  padding: 1rem;
}

.use-welcome-bonus-btn {
  background-color: #81c784; /* Light green for a friendly look */
  color: white; /* White text for contrast */
  border: 2px solid #66bb6a; /* Darker green border */
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.use-welcome-bonus-btn:hover {
  background-color: #66bb6a; /* Slightly darker on hover */
}
.points-description {
  text-align: left;  /* Align all text to the left */
  padding-left: 20px;  /* Consistent padding for text start */
  line-height: 1.5;  /* Improve readability with line spacing */
  font-size: 14px; /* Adjust font size if necessary */
  margin-top: 10px; /* Add space between paragraphs */
}


.pay-registration-fee {
  font-family: Arial, Helvetica, sans-serif; /* A clean and modern font */
  font-size: 2.0rem; /* Slightly larger for prominence */
  font-weight: 700; /* Bolder for emphasis */
  color: #333; /* Dark gray for better contrast */
  margin: 0 0 1.5rem 0; /* Spacing adjustments */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Adds a subtle shadow */
  border-bottom: 2px solid #FF7E5F; /* Bottom border for emphasis */
  padding-bottom: 8px; /* Padding for the border */
}

/* For the description text */
.pay-registration-fee-description {
  font-size: 1rem; /* Increased for readability */
  font-weight: 400; 
  color: #555; /* Softer color for balance */
  line-height: 1.6rem; /* Increased for better readability */
  margin: 0.5rem 0 1rem 0; /* Adjusted margin for spacing */
  text-align: center; /* Centered to match the h1 */
}


