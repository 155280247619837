.BlogModal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-animation-name: fadeIn;
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
}

.BlogModal-content {
  position: fixed;
  bottom: 0;
  top: 3.5rem;
  background-color: #fefefe;
  width: 100%;
  -webkit-animation-name: slideIn;
  -webkit-animation-duration: 0.4s;
  animation-name: slideIn;
  animation-duration: 0.4s;
  border-radius: 8px 8px 0 0;
  overflow-y: auto;
}
.BlogModal-content::-webkit-scrollbar {
  width: 0;
}

.BlogModal-close-icon {
  width: 18px;
  position: fixed;
  right: 1rem;
  top: 1.7rem;
  cursor: pointer;
}

.BlogModal-container {
  width: 70%;
  margin: 2rem auto;
}
.BlogModal-container h1 {
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 0;
}

.author-text {
  font-size: 0.9rem;
  margin-top: 0.8rem;
  margin-bottom: 2rem;
}

/* .BlogModal-img-desc-div {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
} */

.BlogModal-img-desc-div {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* .BlogModal-imgDiv {
  width: 45%;
  margin-top: 1rem;
  margin-bottom: 1rem;
} */

.BlogModal-imgDiv {
  width: 100%;
  /* margin: auto; */
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.blog-full-img {
  width: 100%;
  height: 100%;
  border-radius: 0.8rem;
}

/* .blogModal-desc {
  width: 45%;
  height: 98%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3rem;
  color: #777777;
  margin-top: 1rem;
  margin-bottom: 1rem;
} */

.blogModal-desc {
  width: 100%;
  height: 98%;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3rem;
  color: #777777;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* .blog-full-img {
  margin-top: 1rem;
  width: 100%;
  border-radius: 0.5rem;
}


.blogModal-desc {
  margin-top: 1.5rem;
} */

@media screen and (max-width: 560px) {
  .BlogModal-container {
    width: 90%;
    margin: 1.8rem auto;
  }
  .BlogModal-title {
    font-size: 24px;
    font-weight: 600;
  }

  .author-text {
    font-size: 14px;
    font-weight: 600;
    margin-top: 0.8rem;
    margin-bottom: 2rem;
  }
}
