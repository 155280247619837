
.TermsAndConditionsPage-container {
    padding: 2rem;
    margin-top: 70px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center; 
  }
  
  .terms-title {
    margin-bottom: 2rem;
  }
  
  .terms-img {
    width: 100%;
    max-width: 500px;
    height: auto;
    padding: 2rem;
    border-radius: 10px;
    object-fit: cover; 
    transition: transform 0.3s ease-in-out;
  }
  
  .tandc-subtitle {
    font-size: 1.5rem;
    margin-top: 2rem;
  }
  
  .tandc-p {
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .terms-title {
      font-size: 1.5rem;
    }
  }
  