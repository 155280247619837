.dropdown-item {
    max-width: 100%;
  }
  
  .form-select {
    font-size: 15px;
    border-radius: 5rem;
    border-style: solid;
    border-width: 4px;
    border-color: rgb(118, 119, 117);
    outline: none;
    box-shadow: none;
    height: 2.3rem;
    margin-bottom: 0.5rem;
  }
  
  .form-select:focus {
    font-size: 15px;
    border-radius: 5rem;
    border-style: solid;
    border-width: 4px;
    border-color: rgb(118, 119, 117);
    outline: none;
    box-shadow: none;
    height: 2.4rem;
    margin-bottom: 0.5rem;
    outline: none;
  }
  
  .form-select-three {
    font-size: 15px;
    border-radius: 5rem;
    border-style: solid;
    border-width: 4px;
    border-color: rgb(118, 119, 117);
    outline: none;
    box-shadow: none;
    height: 2.3rem;
    margin-bottom: 0.5rem;
    max-width: 8.1rem;
    padding: 10px !important;
  }
  
  .custom-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-62%);
  }
  
  .error-message {
    color: red;
  }
  
  .schedule-btn {
    display: flex;
    padding: 8px 20px;
    background-color: #ffb53e;
    color: #fff;
    border-radius: 20px;
    text-decoration: none;
    margin-top: 15px;
    height: 2rem;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .schedule-btn:hover {
    background-color: #ff9900;
  }
  
  .dropdown-item-date {
    width: 8rem;
  }
  
  @media screen and (max-width: 1068px) {
    .dropdown-item {
      width: 9rem;
    }
  
    .form-select {
      font-size: 14px;
      border-radius: 5rem;
      border-style: solid;
      border-width: 4px;
      border-color: rgb(118, 119, 117);
      outline: none;
      box-shadow: none;
      height: 2.3rem;
      margin-bottom: 0.5rem;
    }
  
    .custom-icon {
      width: 2px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .dropdown-item,
    .form-select,
    .form-select-three,
    .dropdown-item-date {
      width: 100%;
    }
  
    .form-select,
    .form-select-three,
    .dropdown-item-date {
      max-width: 100%;
    }
  }
  
  @media screen and (max-width: 480px) {
    .form-select-three,
    .dropdown-item-date {
      width: 100%;
      max-width: 100%;
    }
  }
  