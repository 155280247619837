.ProjectPage {
    margin-top: 56px;
  }
  
  .ProjectPage-title-container {
    background-color: #383838;
    color: #ffffff;
    padding: 3rem 0 8rem 0;
  }
  .ProjectPage-title-container h1 {
    font-size: 2.3rem;
    font-weight: 400;
    width: 70%;
    margin: 0 auto 0.7rem auto;
    text-align: center;
    color: #ffffff;
  }
  .ProjectPage-title-container p {
    font-size: 0.8rem;
    font-weight: 300;
    width: 55%;
    margin: 0 auto;
    text-align: center;
    line-height: 1.2rem;
  }
  
  .events-card-container {
    margin: -6.5rem 0 6rem 0;
  }
  
  @media screen and (max-width: 560px) {
    .ProjectPage-title-container h1 {
      font-size: 30px;
      font-weight: 400;
      width: 90%;
      margin: 0 auto 0.7rem auto;
      text-align: center;
    }
    .ProjectPage-title-container p {
      font-size: 14px;
      font-weight: 300;
      width: 90%;
      margin: 0 auto;
      text-align: center;
      line-height: 1.2rem;
    }
  }
  