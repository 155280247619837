.RegisterModal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.RegisterModal-content {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fefefe;
  width: 40%;
  border-radius: 10px;
}
.RegisterModal-content h1 {
  margin-top: 1rem;
  font-weight: 400;
  text-align: center;
}

.RegisterModal-close-icon {
  width: 18px;
  float: right;
  margin: -1.6rem 0.3rem 0 0;
  cursor: pointer;
}

.RegisterModal-type-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 80%;
  margin: 0 auto;
}

.RegisterModal-type-div-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 0 auto;
}

.RegisterModal-type-div p {
  margin-right: 2rem;
}

.RegisterModal-radio-div input {
  margin-right: 0.5rem;
}

.members-list-div {
  text-align: center;
}
.members-list-div p {
  font-size: 0.8rem;
  color: grey;
  width: 80%;
  margin: 1rem auto 0.4rem auto;
}
.members-list-div textarea {
  width: 73%;
  border: 1px solid #d1d9d9;
  padding: 0.6rem 1rem;
  border-radius: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: grey;
  font-size: 0.8rem;
}
.members-list-div textarea:focus {
  outline: none;
}

.RegisterModal-button-div {
  width: 100%;
  text-align: center;
  margin: 2rem 0;
}

.RegisterModal-button {
  width: 25%;
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.5rem 0;
  border-radius: 2rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.box {
  margin: 0.6rem 0;
  position: relative;
}

.member-input {
  padding: 10px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #d1d9d9;
}

.btn-box {
  position: absolute;
  right: 15%;
  bottom: 0;
}

.btn-box button {
  outline: none;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 15px;
  /* padding: 5px; */
  background: linear-gradient(to right, #fb794a, #edb038);
  color: #fff;
  margin: 0 5px;
}

@media screen and (max-width: 560px) {
  .RegisterModal-content {
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fefefe;
    width: 90%;
    border-radius: 10px;
  }
  .RegisterModal-type-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    margin: 0 auto;
  }
  .RegisterModal-type-div-center {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 84%;
    margin: 0 auto;
  }
  .RegisterModal-content h1 {
    margin-top: 1rem;
    font-weight: 400;
    font-size: 1.5rem;
    text-align: center;
  }

  .RegisterModal-button-div {
    width: 100%;
    text-align: center;
    margin: 1.5rem 0;
  }

  .RegisterModal-button {
    font-size: 0.8rem;
  }

  .member-input {
    padding: 10px;
    outline: none;
    border-radius: 10px;
    border: 1px solid #d1d9d9;
    margin-right: 25px;
  }
  .btn-box {
    position: absolute;
    right: 1%;
    bottom: 0;
  }
}
