.WorkshopPage {
  margin-top: 56px;
}

.WorkshopPage-title-container {
  background-color: #383838;
  color: #ffffff;
  padding: 3rem 0 8rem 0;
}
.WorkshopPage-title-container h1 {
  font-size: 2.3rem;
  font-weight: 400;
  width: 70%;
  text-align: center;
  margin: 0 auto 0.7rem auto;
  color: #ffffff;
}
.WorkshopPage-title-container p {
  font-size: 0.8rem;
  font-weight: 300;
  width: 55%;
  line-height: 1.2rem;
  text-align: center;
  margin: 0 auto;
}

.workshop-card-container {
  margin: -6.5rem 0 6rem 0;
}

@media screen and (max-width: 560px) {
  .WorkshopPage-title-container h1 {
    font-size: 30px;
    font-weight: 400;
    width: 90%;
    text-align: center;
    margin: 0 auto 0.7rem auto;
  }
  .WorkshopPage-title-container p {
    font-size: 14px;
    font-weight: 300;
    width: 90%;
    line-height: 1.2rem;
    text-align: center;
    margin: 0 auto;
  }
}
