.ChapterCard {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: 500;
  border-bottom: 1px solid #d1d9d9;
  background-color: #ffffff;
}

.ChapterCard-left {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 2.5rem;
}
.ChapterCard-left p {
  width: 60%;
}

.ChapterCard-right {
  color: #3eac72;
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 2.5rem;
}
.ChapterCard-right button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.6rem 2.2rem;
  border-radius: 2rem;
  font-weight: 500;
}

.unlocked-icons {
  margin-left: 0.5rem;
  width: 14px;
  margin-bottom: -3px;
}

.lock-icon {
  width: 1.9rem;
}

.read-watch-button {
  cursor: pointer;
}
