.page-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.page-content {
  width: 40%;
  height: auto;
  background-color: #fff;
  margin: auto;
  margin-top: 1.5rem;
  padding: 1rem;
  border-radius: 10px;
}

.title-div {
  text-align: center;
  letter-spacing: 1.5px;
  border-bottom: 1px solid #b1b5b5;
  width: 80%;
  margin: auto;
  margin-bottom: 1.5rem;
}

.title-div h1 {
  margin-bottom: 10px;
}

.ec_logo {
  height: 10%;
  width: 50%;
}

.forgotpass-inputDiv {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: auto;
  margin-bottom: 0.5rem;
  outline: none;
}

.forgotpass-input {
  width: 45%;
  margin: 0.3rem auto;
  padding: 0.8rem 1rem;
  font-family: 'Roboto', sans-serif;
  font-size: 0.8rem;
  font-weight: 500;
  border: 1px solid #b1b5b5;
  border-radius: 2rem;
  outline: none;
}

.forgotpass-inputDiv input.error {
  border: 1px solid red;
}

.forgotpass-inputDiv .errorMessage {
  color: red;
  font-size: 0.75em;
  display: relative;
  /* text-align: left; */
  margin-bottom: 5px;
  margin-left: 27%;
  /* margin-right: auto; */
}

.forgotpass-button {
  width: 50%;
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.7rem 1.8rem;
  border-radius: 2rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 0.8rem;
  /* margin: auto; */
  margin: 1.3rem auto;
  margin-top: 0.5rem;
}
