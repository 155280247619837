.HelpPage {
  margin-top: 57px;
  margin-bottom: -2px;
  font-family: 'Arial', sans-serif; /* Adjust the font family as needed */
}
.HelpPage-container {
  display: flex;
  flex-direction: row;
  height: auto; /* Adjust height to be flexible */
  gap: 1rem; /* Add gap between the two sections */
  padding-bottom: 2rem; /* Add padding to avoid overlap with footer */
}

.HelpPage-container-right {
  width: 60%; 
  margin-top: 1.3rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* Ensure that the left and right sections have consistent padding and spacing */
.HelpPage-container-left {
  width: 40%;
  background-color: #383838;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 560px) {
  .HelpPage-container {
    flex-direction: column;
    height: auto; /* Ensure height adjusts for smaller screens */
  }

  .HelpPage-container-left,
  .HelpPage-container-right {
    width: 100%; /* Full width for both containers */
    padding: 1rem;
  }
}


.HelpPage-container-left h1 {
  font-size: 2.5rem;
  margin: 0;
  text-align: center;
}

.HelpPage-text-box {
  max-width: 700px; /* Set a maximum width for consistency */
  margin: 0 auto 2rem auto; /* Center the box with margin at the bottom */
  padding: 1rem; /* Padding for internal spacing */
  background-color: #ffffff; /* Clean white background for contrast */
  border-radius: 12px; /* Slightly rounded corners for a modern look */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Enhanced shadow for depth */
}

.HelpPage-text-box h1 {
  font-size: 2.2rem; /* Set font size for the heading */
  color: #333; /* Dark text color for better readability */
  margin-bottom: 1.2rem; /* Space below the heading */
  font-weight: 600; /* Bold font for emphasis */
}

.HelpPage-text-box p {
  font-size: 1rem; /* Set font size for paragraphs */
  color: #666; /* Medium gray color for text */
  line-height: 1.6; /* Line height for readability */
  margin-bottom: 0.9rem; /* Space below paragraphs */
}

@media screen and (max-width: 560px) {
  .HelpPage-text-box {
    width: 90%; 
    padding: 1.5rem;
  }

  .HelpPage-text-box h1 {
    font-size: 1.8rem; 
    margin-bottom: 1rem; 
  }

  .HelpPage-text-box p {
    font-size: 0.9rem; 
    margin-bottom: 1rem; 
  }
}
.error {
  border: 1px solid red; 
}
.errorMessage {
  color: red; /* Error message color */
  font-size: 12px; /* Font size for error message */
}


.HelpPage-inputDiv-name,
.HelpPage-inputDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem; /* Space between input sections */
}

.HelpPage-inputDiv-name input {
  width: 100%;
  border: 1px solid #d1d9d9;
  padding: 0.9rem 1.2rem; /* Padding inside input fields */
  border-radius: 0.5rem; /* Rounded corners */
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #333; /* Darker text color for readability */
  font-size: 0.9rem; /* Adjusted font size for better readability */
  margin-bottom: 0.5rem; /* Space between input fields */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.HelpPage-inputDiv-name input.error {
  border-color: #e74c3c; /* Red border for errors */
}

.HelpPage-inputDiv-name input:focus {
  border-color: #fb794a; /* Change border color on focus */
  outline: none;
  box-shadow: 0 0 4px rgba(251, 121, 74, 0.3); /* Shadow for focus */
}

.HelpPage-inputDiv-name input::placeholder {
  color: #888; /* Placeholder color */
}
.HelpPage-container-right {
  /* width: 60%; */
  padding: 1rem;
  
  /* display: flex;
  flex-direction: column;
  justify-content: center; */
}
.HelpPage-input {
  border: 1px solid #d1d9d9;
  padding: 0.9rem 1.2rem; /* Padding inside input fields */
  border-radius: 0.5rem; /* Rounded corners */
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  color: #333; /* Darker text color for readability */
  font-size: 0.9rem; /* Adjusted font size for better readability */
  margin-bottom: 0.5rem; /* Space between input fields */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.HelpPage-input.error {
  border-color: #e74c3c; /* Red border for errors */
}

.HelpPage-input:focus {
  border-color: #fb794a; /* Change border color on focus */
  outline: none;
  box-shadow: 0 0 4px rgba(251, 121, 74, 0.3); /* Shadow for focus */
}

.HelpPage-input::placeholder {
  color: #888; /* Placeholder color */
}

.HelpPage-button-container {
  text-align: center;
  margin-top: 1.5rem;
}

.HelpPage-button-container button {
  border: none;
  cursor: pointer;
  color: #ffffff;
  background: linear-gradient(to right, #fb794a, #edb038);
  padding: 0.7rem 2rem;
  border-radius: 2rem;
  font-size: 0.9rem;
  font-weight: 500;
  width: 100%;
  transition: background 0.3s ease, transform 0.3s ease;
}

.HelpPage-button-container button:hover {
  background: linear-gradient(to right, #edb038, #fb794a);
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.help-img {
  width: 100%;
  max-width: 400px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow for the image */
}

.errorMessage {
  color: red;
  font-size: 0.75rem;
  margin: 0.5rem 0;
}

@media screen and (max-width: 850px) {
  .HelpPage-container {
    flex-direction: column;
    height: auto;
  }

  .HelpPage-container-left,
  .HelpPage-container-right {
    width: 100%;
    padding: 1rem;
  }

  .HelpPage-text-box {
    width: 100%;
    margin: 0 auto 2rem auto;
  }

  .HelpPage-text-box p {
    font-size: 0.9rem;
  }

  .HelpPage-inputDiv-name {
    flex-direction: column;
  }

  .HelpPage-inputDiv-name input {
    width: 100%;
    margin-bottom: 1rem;
  }

  .HelpPage-button-container button {
    width: 100%;
  }
}
